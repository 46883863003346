
import { defineComponent, PropType } from 'vue'
import { Mark, Styles } from './typings'

import './styles/mark.scss';

export default defineComponent({
  name: 'VueSliderMark',
  emits: ['PressLabel'],
  props: {
    mark: {
      type: Object as PropType<Mark>,
      required: true,
    },

    hideLabel: { type: Boolean },

    stepStyle: { type: Object as PropType<Styles>, default: () => ({}) },

    stepActiveStyle: { type: Object as PropType<Styles>, default: () => ({}) },

    labelStyle: { type: Object as PropType<Styles>, default: () => ({}) },

    labelActiveStyle: { type: Object as PropType<Styles>, default: () => ({}) },
  },
  computed: {
    marksClasses() {
      return [
        'vue-slider-mark',
        {
          'vue-slider-mark-active': this.mark.active,
        },
      ]
    },
    stepClasses() {
      return [
        'vue-slider-mark-step',
        {
          'vue-slider-mark-step-active': this.mark.active,
        },
      ]
    },
    labelClasses() {
      return [
        'vue-slider-mark-label',
        {
          'vue-slider-mark-label-active': this.mark.active,
        },
      ]
    },
  },
  methods: {
    labelClickHandle(e: MouseEvent | TouchEvent) {
      e.stopPropagation()
      this.$emit('PressLabel', this.mark.pos)
    },
  }
})
